 /**
 * @type {import("../types").Config}
 */
module.exports = {
  shortName: "TK Olymp",
  copyrightLine: "© 2024 TK Olymp Olomouc, z. s.",
  favicon: '',
  enableHome: true,
  enableArticles: true,
  themePrimary: '#ed1734',
  themeAccent: 'red',
  themeNeutral: 'gray',
};
